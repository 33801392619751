import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { H3 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';

import css from './suggest-checking.module.scss';

interface SuggestCheckingViewProps {
  onCheck: () => void;
}

export const SuggestCheckingView = (
  props: SuggestCheckingViewProps
): JSX.Element => {
  const { onCheck } = props;
  const { t } = useTranslation();

  return (
    <div className={css.body}>
      <H3>{t('check-pseudo.suggest.title')}</H3>
      <Text>{t('check-pseudo.suggest.caption')}</Text>
      <Button onClick={onCheck}>{t('check-pseudo.suggest.button')}</Button>
    </div>
  );
};
