import classNames from 'classnames';
import { ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner } from '../spinner/spinner';
import css from './button.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'contained' | 'outlined';
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

export const Button = ({
  type = 'button',
  variant = 'contained',
  disabled = false,
  isLoading = false,
  onClick = () => {},
  className,
  children,
  ...props
}: ButtonProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <button
      className={classNames(
        css.button,
        variant === 'contained' && css.primary,
        variant === 'outlined' && css.secondary,
        isLoading && css.loading,
        disabled && css.disabled,
        className
      )}
      onClick={onClick}
      disabled={disabled}
      type={type}
      {...props}>
      {isLoading ? (
        <>
          <Spinner /> {t('shared.button.loading')}
        </>
      ) : (
        children
      )}
    </button>
  );
};
