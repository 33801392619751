import classNames from 'classnames';
import { Text } from '@app/shared/typography/text/text';
import { Icon } from '@app/shared/icon/icon';

import css from './notification-item.module.scss';

interface NotificationItemProps {
  onClick?: () => void;
  isDisabled?: boolean;
  children: string;
}

export const NotificationItem = (props: NotificationItemProps): JSX.Element => {
  const { children, isDisabled = false, onClick } = props;

  return (
    <button onClick={onClick}>
      <div
        className={classNames(
          css.container,
          isDisabled && css.containerDisabled
        )}>
        <Icon
          name="alert-triangle"
          className={classNames(css.icon, isDisabled && css.iconDisabled)}
        />
        <Text className={css.text}>{children}</Text>

        {onClick && (
          <Icon
            name="chevron-right"
            className={classNames(css.icon, isDisabled && css.iconDisabled)}
          />
        )}
      </div>
    </button>
  );
};
