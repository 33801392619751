import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { Overlay } from '@app/shared/overlay/overlay';
import { Spinner } from '@app/shared/spinner/spinner';
import { Fade } from '@app/shared/fade/fade';
import { UserModel } from '@app/core/model/user.model';
import { InvoiceModel } from '@app/core/model/invoice.model';

import { NewsCard } from './component/news-card/news-card';

import css from './dashboard.module.scss';
import { RechargeCard } from './component/recharge-card/recharge-card';
import { Header } from './component/header/header';
import { ServiceSection } from './component/service-section/service-section';

interface DashboardViewProps {
  isCharging: boolean;
  chargeStartedAt?: Date;
  chargePointName?: string;
  lastInvoice: InvoiceModel | null;
  consumptions?: number[];
  user?: UserModel;
  loading: boolean;
  onNavigateToInvoiceSummary: (invoiceId: string) => void;
  onNavigateToScan: () => void;
  onNavigateToCharge: () => void;
}

export const DashboardView = ({
  isCharging,
  chargeStartedAt,
  chargePointName,
  lastInvoice,
  consumptions = [],
  user,
  loading = false,
  onNavigateToInvoiceSummary,
  onNavigateToScan,
  onNavigateToCharge,
}: DashboardViewProps): JSX.Element => {
  const handleClickOnRechargeCard = () => {
    if (isCharging) {
      return onNavigateToCharge();
    }

    if (lastInvoice?.invoiceId && !lastInvoice?.proforma) {
      return onNavigateToInvoiceSummary(lastInvoice.invoiceId);
    }

    return onNavigateToScan();
  };

  return (
    <>
      <PageLayout className={css.page}>
        <Header
          firstName={user?.firstName}
          lastName={user?.lastName}
          pseudo={user?.pseudo}
        />

        <RechargeCard
          chargePointName={chargePointName}
          startedAt={isCharging ? chargeStartedAt : lastInvoice?.startedAt}
          isCharging={isCharging}
          onClick={handleClickOnRechargeCard}
        />

        <ServiceSection
          chargePointName={chargePointName}
          lastInvoice={lastInvoice}
          consumptions={consumptions}
          onNavigateToScan={onNavigateToScan}
          onClickOnInvoice={handleClickOnRechargeCard}
        />

        <NewsCard />

        <PageLayout.AppbarFooter />
      </PageLayout>

      <Fade show={loading}>
        <Overlay blur>
          <Spinner />
        </Overlay>
      </Fade>
    </>
  );
};
