import { useTranslation } from 'react-i18next';

import {
  ACCOUNT_PATH,
  MANAGE_AUTHENTICATION_PATH,
} from '@app/config/app-paths.const';
import {
  PatchUserPreferenceModel,
  UserPreferenceKey,
  UserPreferenceModel,
} from '@app/core/model/user-preference.model';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';

import { Divider } from '@app/shared/divider/divider';
import { Icon } from '@app/shared/icon/icon';
import { Link } from '@app/shared/typography/link/link';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { AllNotification } from './component/all-notifications/all-notifications';
import css from './user-preference.module.scss';
import { UserPreference } from './component/user-preference/user-preference';

interface UserPreferenceViewProps {
  isEmailAddressVerified?: boolean;
  pending?: PatchUserPreferenceModel;
  isAllActive: boolean;
  onChangeAll: (value: boolean) => void;
  userPreference?: UserPreferenceModel;
  onChange: (key: UserPreferenceKey, value: boolean) => void;
}

export function UserPreferenceView({
  isEmailAddressVerified = false,
  pending,
  isAllActive,
  onChangeAll,
  userPreference,
  onChange,
}: UserPreferenceViewProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout withHomeButton>
      <PageLayout.Title
        icon="bell-04"
        title={t('account.user-preference-label')}
        backTo={ACCOUNT_PATH}
      />

      <PageLayout.Body className={css.body}>
        <AllNotification
          name="all"
          requireEmailVerified={!isEmailAddressVerified}
          isActive={isAllActive}
          handleChange={onChangeAll}
          pending={
            pending?.autoSendPaymentTicketByMail &&
            pending?.autoSendInvoiceByMail
          }
        />

        {!isEmailAddressVerified && (
          <div className={css.verifyMail}>
            <Icon name="info-circle" />
            <Link onClick={() => navigate(MANAGE_AUTHENTICATION_PATH)}>
              {t('user-preference.email-required')}
            </Link>
          </div>
        )}

        <Divider type="solid" />

        <div
          className={classNames([
            css.mailLabel,
            !isEmailAddressVerified && css.isDisabled,
          ])}>
          {t('user-preference.all-notifications.email')}
          <Icon name="mail-02" />
        </div>

        <UserPreference
          requireEmailVerified={!isEmailAddressVerified}
          label={t('user-preference.auto-send-charge-end-by-mail.label')}
          isActive={userPreference?.autoSendChargeEndByMail ?? false}
          pending={pending?.autoSendChargeEndByMail}
          name="autoSendChargeEndByMail"
          onChange={(state) => onChange('autoSendChargeEndByMail', state)}
        />

        <UserPreference
          requireEmailVerified={!isEmailAddressVerified}
          label={t('user-preference.auto-send-payment-ticket-by-mail.label')}
          isActive={userPreference?.autoSendPaymentTicketByMail ?? false}
          pending={pending?.autoSendPaymentTicketByMail}
          name="autoSendPaymentTicketByMail"
          onChange={(state) => onChange('autoSendPaymentTicketByMail', state)}
        />

        <UserPreference
          requireEmailVerified={!isEmailAddressVerified}
          label={t('user-preference.auto-send-invoice-by-mail.label')}
          isActive={userPreference?.autoSendInvoiceByMail ?? false}
          pending={pending?.autoSendInvoiceByMail}
          name="autoSendInvoiceByMail"
          onChange={(state) => onChange('autoSendInvoiceByMail', state)}
        />

        <UserPreference
          requireEmailVerified={!isEmailAddressVerified}
          label={t('user-preference.enable-pseudo-check.label')}
          isActive={userPreference?.enablePseudoCheck ?? false}
          pending={pending?.enablePseudoCheck}
          name="enablePseudoCheck"
          onChange={(state) => onChange('enablePseudoCheck', state)}
        />
      </PageLayout.Body>

      <PageLayout.AppbarFooter />
    </PageLayout>
  );
}
