import { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import css from './collapsible.module.scss';
import { Spinner } from '../spinner/spinner';

type CollapsibleProps = {
  isOpen: boolean;
  children: ReactNode;
};

export const Collapsible = (props: CollapsibleProps): JSX.Element => {
  const { isOpen, children } = props;
  const collapseRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  const getWrapperSize = () =>
    wrapperRef.current ? wrapperRef.current.clientHeight : 0;

  const collapsedSize = 0;

  useLayoutEffect(() => {
    if (collapseRef.current) {
      const wrapperSize = getWrapperSize();
      if (wrapperSize === 0) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
      collapseRef.current.style.maxHeight = `${
        isOpen ? wrapperSize : collapsedSize
      }px`;
    }
  }, [isOpen, children, wrapperRef]);

  return (
    <div className={css.container}>
      <div ref={collapseRef} className={classNames(css.collapsible)}>
        <div ref={wrapperRef}>{children}</div>
      </div>
      {isLoading && <Spinner className={css.spinner} />}
    </div>
  );
};
