import { useMemo } from 'react';
import { WithUserProps } from '@app/router/guard/with-user.guard';

import { UserPreferenceView } from './user-preference.view';
import { useUserPreference } from './hook/use-user-preference';

export const UserPreferenceController = (props: WithUserProps): JSX.Element => {
  const { user } = props;

  const { pending, onChangeAll, onChangeOne } = useUserPreference();

  const isAllActive = useMemo(
    () =>
      (user?.preferences?.autoSendInvoiceByMail &&
        user?.preferences?.autoSendPaymentTicketByMail &&
        user?.preferences?.enablePseudoCheck &&
        user?.preferences?.autoSendChargeEndByMail) ??
      false,
    [user.preferences]
  );

  return (
    <UserPreferenceView
      isEmailAddressVerified={user.isEmailAddressVerified}
      pending={pending}
      isAllActive={isAllActive}
      userPreference={user.preferences}
      onChangeAll={onChangeAll}
      onChange={onChangeOne}
    />
  );
};
