import { ReactNode, useCallback, useState } from 'react';

import { VerifyEmailOtpProcessController } from '@app/feature/email-otp-process/verify-email-otp-process/verify-email-otp-process.controller';
import { StartEmailOtpProcessController } from '@app/feature/email-otp-process/start-email-otp-process/start-email-otp-process.controller';
import { TranslationPaths } from '@app/core/helper/use-typed-translation';
import {
  EmailOtpProcessStartRequestModel,
  EmailOtpProcessVerificationRequestModel,
} from '@app/core/model/email-otp-process.model';

export interface EmailOTPProcessControllerProps {
  remainingLockTimeInMin?: number;
  translationKey?: TranslationPaths;
  emailAddress?: string;
  isReadonly?: boolean;
  appendFooter?: ReactNode;
  onRequestOTP: (payload: EmailOtpProcessStartRequestModel) => Promise<void>;
  onValidateOTP: (
    payload: EmailOtpProcessVerificationRequestModel
  ) => Promise<void>;
  onRequestAgainOTP: (
    payload: EmailOtpProcessStartRequestModel
  ) => Promise<void>;
  onSkip?: () => void;
}

export const EmailOTPProcessController = ({
  remainingLockTimeInMin,
  emailAddress = '',
  isReadonly = false,
  translationKey,
  appendFooter,
  onRequestOTP,
  onValidateOTP,
  onRequestAgainOTP,
  onSkip,
}: EmailOTPProcessControllerProps): JSX.Element => {
  const [showCodeInput, setShowCodeInput] = useState(false);

  const [payload, setPayload] = useState<EmailOtpProcessStartRequestModel>({
    emailAddress,
  });

  const handleAskForEmailOtpSuccess = useCallback(
    (data: EmailOtpProcessStartRequestModel) => {
      setPayload(data);
      setShowCodeInput(true);
    },
    []
  );

  return showCodeInput && payload ? (
    <VerifyEmailOtpProcessController
      remainingLockTimeInMin={remainingLockTimeInMin}
      payload={payload}
      onValidateOTP={onValidateOTP}
      onRequestAgainOTP={onRequestAgainOTP}
      translationKey={translationKey}
    />
  ) : (
    <StartEmailOtpProcessController
      appendFooter={appendFooter}
      remainingLockTimeInMin={remainingLockTimeInMin}
      emailAddress={emailAddress}
      isReadonly={isReadonly}
      translationKey={translationKey}
      onRequestOTP={onRequestOTP}
      onNext={handleAskForEmailOtpSuccess}
      onSkip={onSkip}
    />
  );
};
