import { useTranslation } from 'react-i18next';

import { Dialog } from '@app/shared/dialog/dialog';
import { Button } from '@app/shared/button/button';
import { Link } from '@app/shared/typography/link/link';
import { H2 } from '@app/shared/typography/heading/heading';
import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';

import css from './open-tab.module.scss';

export interface OpenTabDialogProps {
  url: string;
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

export const OpenTabDialog = ({
  url,
  title,
  isOpen,
  onClose,
}: OpenTabDialogProps): JSX.Element => {
  const { t } = useTranslation();

  const handleRedirect = () => {
    window.open(url, '_blank');
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogLayout>
        <DialogLayout.Body>
          <Icon className={css.icon} name="link-external-02" color="primary" />

          <H2>{title}</H2>

          <Text>{t('shared.open-tab-dialog.caption')}</Text>

          <Link className={css.link} onClick={handleRedirect}>
            {url}
          </Link>
        </DialogLayout.Body>

        <DialogLayout.Footer>
          <Button variant="outlined" onClick={onClose}>
            {t('shared.open-tab-dialog.cancel-button')}
          </Button>
          <Button onClick={handleRedirect}>
            {t('shared.open-tab-dialog.validate-button')}
          </Button>
        </DialogLayout.Footer>
      </DialogLayout>
    </Dialog>
  );
};
