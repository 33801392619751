import { useCallback, useState } from 'react';

import { showDefaultErrorMessage } from '@app/core/error/show-error-message';
import { useEmailAuthentication } from '@app/feature/authentication/view/email-authentication/use-email-authentication';
import { RecoverAccountProps } from '@app/feature/recover-account/recover-account-props';
import { EmailOtpProcessStartRequestModel } from '@app/core/model/email-otp-process.model';
import { VerifyEmailOtpProcessController } from '@app/feature/email-otp-process/verify-email-otp-process/verify-email-otp-process.controller';
import { StartEmailOtpProcessController } from '@app/feature/email-otp-process/start-email-otp-process/start-email-otp-process.controller';
import { AuthenticationMethodEnum } from '@app/core/model/enum/authentication-method.enum';
import { ApiError } from '@app/core/error/api-error';
import { HttpStatusEnum } from '@app/config/error-config';
import { useDialog } from '@app/shared/dialog/use-dialog';
import { AccountNotFoundErrorDialog } from '@app/shared/account-not-found-error-dialog/account-not-found-error-dialog';

import css from './recover-account-with-email.module.scss';

interface RecoverAccountWithEmailControllerProps extends RecoverAccountProps {
  emailAddress?: string;
  onSkip: () => void;
}

export const RecoverAccountWithEmailController = (
  props: RecoverAccountWithEmailControllerProps
): JSX.Element => {
  const { emailAddress, onAuthenticate, onSkip } = props;

  const {
    isDialogOpen: isAccountNotFoundErrorDialogOpen,
    openDialog: openAccountNotFoundErrorDialog,
    closeDialog: closeAccountNotFoundErrorDialog,
  } = useDialog();

  const handleSuccess = useCallback(
    (jwt: string, identifier: string) => {
      onAuthenticate(jwt, AuthenticationMethodEnum.EMAIL, identifier);
    },
    [onAuthenticate]
  );

  const handleError = useCallback(
    (error: ApiError) => {
      if (error.status === HttpStatusEnum.NOT_FOUND) {
        return openAccountNotFoundErrorDialog();
      }

      return showDefaultErrorMessage();
    },
    [openAccountNotFoundErrorDialog]
  );

  const { onStart, onVerify, remainingLockTimeInMin } = useEmailAuthentication({
    onStartError: handleError,
    onSuccess: handleSuccess,
  });

  const [showCodeInput, setShowCodeInput] = useState(false);

  const [payload, setPayload] = useState<EmailOtpProcessStartRequestModel>();

  const handleAskForEmailOtpSuccess = useCallback(
    (data: EmailOtpProcessStartRequestModel) => {
      setPayload(data);
      setShowCodeInput(true);
    },
    []
  );

  return (
    <>
      {showCodeInput && payload ? (
        <VerifyEmailOtpProcessController
          remainingLockTimeInMin={remainingLockTimeInMin}
          payload={payload}
          onValidateOTP={onVerify}
          onRequestAgainOTP={onStart}
          translationKey="recover-account.email"
        />
      ) : (
        <div className={css.container}>
          <StartEmailOtpProcessController
            autoFocus
            emailAddress={emailAddress}
            remainingLockTimeInMin={remainingLockTimeInMin}
            className={css.startContainer}
            translationKey="recover-account.email"
            onRequestOTP={onStart}
            onNext={handleAskForEmailOtpSuccess}
            onSkip={onSkip}
          />
        </div>
      )}

      <AccountNotFoundErrorDialog
        useEmail
        open={isAccountNotFoundErrorDialogOpen}
        onCancel={closeAccountNotFoundErrorDialog}
        onClose={closeAccountNotFoundErrorDialog}
      />
    </>
  );
};
