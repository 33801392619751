import classNames from 'classnames';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { useTranslation } from 'react-i18next';
import { H1 } from '@app/shared/typography/heading/heading';

import { Icon } from '@app/shared/icon/icon';
import { useCallback, useEffect, useState } from 'react';
import css from './start-charging-carousel.module.scss';
import type { StepComponent } from '../step/step.props';
import {
  StartChargingCarouselConfig,
  StartChargingCarouselTypeEnum,
} from './start-charging-carousel.config';
import { StartChargeButton } from '../../start-charge-button/start-charge-button';

interface StartChargingCarouselProps {
  configuration?: StartChargingCarouselTypeEnum;
}

export const StartChargingCarousel = (
  props: StartChargingCarouselProps
): JSX.Element => {
  const { configuration = StartChargingCarouselTypeEnum.ac } = props;
  const { t } = useTranslation();
  const [step, setStep] = useState(0);

  const stepComponents: StepComponent[] =
    StartChargingCarouselConfig[configuration];

  const handleGoToPreviousStep = () => {
    setStep((prevStep) =>
      prevStep === 0 ? stepComponents.length - 1 : prevStep - 1
    );
  };

  const handleGoToNextStep = useCallback(() => {
    setStep((prevStep) =>
      prevStep === stepComponents.length - 1 ? 0 : prevStep + 1
    );
  }, [stepComponents.length]);

  useEffect(() => {
    const i = setInterval(() => {
      handleGoToNextStep();
    }, 3000);

    return () => clearInterval(i);
  }, [handleGoToNextStep, step]);

  return (
    <PageLayout withHomeButton>
      <PageLayout.Header>
        <H1>{t('charging.title.starting')}</H1>
      </PageLayout.Header>

      <PageLayout.Body>
        <div className={classNames(css.carousel)}>
          {stepComponents.map((Component, index) => (
            <div
              style={{ transform: `translate(-${step * 100}%)` }}
              key={Component.name}>
              <Component step={index + 1} />
            </div>
          ))}
        </div>

        <div className={css.indicators}>
          <button onClick={handleGoToPreviousStep}>
            <Icon name="chevron-left" />
          </button>
          <div className={classNames(css.indicator)}>
            {stepComponents.map((Component, index) => (
              <div
                key={Component.name}
                className={step === index ? css.active : ''}
              />
            ))}
          </div>
          <button onClick={handleGoToNextStep}>
            <Icon name="chevron-right" />
          </button>
        </div>

        <PageLayout.Footer>
          <StartChargeButton />
        </PageLayout.Footer>
      </PageLayout.Body>
    </PageLayout>
  );
};
