import { useCallback } from 'react';

import { useWebauthnAuthentication } from '@app/feature/authentication/view/webauthn-authentication/use-webauthn-authentication';
import { WebauthnErrorDialog } from '@app/shared/webauthn-error-dialog/webauthn-error-dialog';
import { useDialog } from '@app/shared/dialog/use-dialog';
import { AuthenticationMethodEnum } from '@app/core/model/enum/authentication-method.enum';
import { ApiError } from '@app/core/error/api-error';
import { HttpStatusEnum } from '@app/config/error-config';
import { AccountNotFoundErrorDialog } from '@app/shared/account-not-found-error-dialog/account-not-found-error-dialog';

import { RecoverAccountProps } from '../recover-account-props';
import { RecoverAccountWithWebauthnView } from './recover-account-with-webauthn.view';

interface RecoverAccountWithWebauthnControllerProps
  extends RecoverAccountProps {
  onNavigateToRegistration: () => void;
}

export const RecoverAccountWithWebauthnController = (
  props: RecoverAccountWithWebauthnControllerProps
): JSX.Element => {
  const { onAuthenticate, onNavigateToRegistration } = props;

  const {
    isDialogOpen: isErrorDialogOpen,
    openDialog: openErrorDialog,
    closeDialog: closeErrorDialog,
  } = useDialog();

  const {
    isDialogOpen: isAccountNotFoundErrorDialogOpen,
    openDialog: openAccountNotFoundErrorDialog,
    closeDialog: closeAccountNotFoundErrorDialog,
  } = useDialog();

  const handleSuccess = useCallback(
    (jwt: string, pseudo: string) => {
      onAuthenticate(jwt, AuthenticationMethodEnum.WEBAUTHN, pseudo);
    },
    [onAuthenticate]
  );

  const handleError = useCallback(
    (error: ApiError) => {
      if (error.status === HttpStatusEnum.NOT_FOUND) {
        return openAccountNotFoundErrorDialog();
      }

      return openErrorDialog();
    },
    [openAccountNotFoundErrorDialog, openErrorDialog]
  );

  const { pending, remainingLockTimeInMin, onStartWebauthn, onRetryWebauthn } =
    useWebauthnAuthentication({
      onError: handleError,
      onSuccess: handleSuccess,
    });

  return (
    <>
      <RecoverAccountWithWebauthnView
        remainingLockTimeInMin={remainingLockTimeInMin}
        onNavigateToRegistration={onNavigateToRegistration}
        pending={pending}
        onSubmit={onStartWebauthn}
      />

      <WebauthnErrorDialog
        open={isErrorDialogOpen}
        onClose={closeErrorDialog}
        onRetry={onRetryWebauthn}
      />

      <AccountNotFoundErrorDialog
        open={isAccountNotFoundErrorDialogOpen}
        onCancel={closeAccountNotFoundErrorDialog}
        onClose={closeAccountNotFoundErrorDialog}
      />
    </>
  );
};
