import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NUMBER_OF_ATTEMPTS_BEFORE_HINT } from '@app/config/check-pseudo.const';
import { useUser } from '@app/core/context-providers/user-context/use-user';
import { setLastValidationToNow } from '@app/feature/check-pseudo/check-pseudo-utils';
import { Button } from '@app/shared/button/button';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';
import { PseudoTag } from '@app/shared/pseudo-tag/pseudo-tag';
import { TextInput } from '@app/shared/text-input/text-input';
import { H1 } from '@app/shared/typography/heading/heading';
import { Link } from '@app/shared/typography/link/link';
import { Text } from '@app/shared/typography/text/text';

import css from './check-pseudo-drawer.module.scss';
import { checkPseudoFormValidationRulesBuild } from './check-pseudo-form-validation-rules.const';

interface CheckPseudoDrawerProps {
  onClose: () => void;
}

interface CheckPseudoForm {
  pseudo: string;
}

export const CheckPseudoDrawer = (
  props: CheckPseudoDrawerProps
): JSX.Element => {
  const { onClose } = props;
  const { t } = useTranslation();
  const { user } = useUser();

  const [showPseudo, setShowPseudo] = useState(false);
  const [numberOfAttempt, setNumberOfAttempt] = useState(0);

  const handleInvalid = () => {
    setNumberOfAttempt((currentValue) => currentValue + 1);
  };

  const handleSuccess = () => {
    setLastValidationToNow();
    onClose();
  };

  useEffect(() => {
    if (numberOfAttempt >= NUMBER_OF_ATTEMPTS_BEFORE_HINT) {
      setShowPseudo(true);
    }
  }, [numberOfAttempt]);

  const {
    register,
    handleSubmit,
    formState: { touchedFields, errors },
  } = useForm<CheckPseudoForm>({
    mode: 'onSubmit',
  });

  return (
    <form
      onSubmit={handleSubmit(handleSuccess, handleInvalid)}
      className={css.form}>
      <DrawerLayout>
        <DrawerLayout.Header>
          <H1>{t('check-pseudo.check.title')}</H1>
          <Text>{t('check-pseudo.check.caption')}</Text>
        </DrawerLayout.Header>

        <DrawerLayout.Body className={css.body}>
          <TextInput
            inputClassName={css.pseudoInput}
            autoFocus
            label={t('check-pseudo.check.pseudo.label')}
            {...register(
              'pseudo',
              checkPseudoFormValidationRulesBuild(user?.pseudo ?? '')
            )}
            isTouched={touchedFields?.pseudo}
            errorMessage={
              errors?.pseudo?.type
                ? t('check-pseudo.check.pseudo.errors.pattern')
                : ''
            }
          />

          {showPseudo && <PseudoTag />}
        </DrawerLayout.Body>

        <DrawerLayout.Footer>
          <Button type="submit">{t('check-pseudo.check.submit-button')}</Button>
          <Link onClick={() => setShowPseudo(true)}>
            {t('check-pseudo.check.forget-link')}
          </Link>
        </DrawerLayout.Footer>
      </DrawerLayout>
    </form>
  );
};
