import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import { InvoiceModel } from '@app/core/model/invoice.model';
import { Text } from '@app/shared/typography/text/text';
import { useTranslation } from 'react-i18next';
import DashboardPlaceholder from '@app/asset/dashboard-placeholder.svg';

import { ChargeInProgressCard } from '../charge-in-progress-card/charge-in-progress-card';
import { ChargeInProgressInvoiceCard } from '../charge-in-progress-invoice-card/charge-in-progress-invoice-card';
import css from './service-section.module.scss';
import { LastInvoiceCard } from '../last-invoice-card/last-invoice-card';
import { LastChargesCard } from '../last-charges-card/last-charges-card';

type Props = {
  chargePointName?: string;
  lastInvoice: InvoiceModel | null;
  consumptions?: number[];
  onNavigateToScan: () => void;
  onClickOnInvoice: () => void;
};

export function ServiceSectionBody(props: Props): JSX.Element {
  const {
    lastInvoice,
    chargePointName,
    consumptions,
    onNavigateToScan,
    onClickOnInvoice,
  } = props;

  const { t } = useTranslation();

  const { startedAt, totalTTC, isCharging } = useCharge();

  if (isCharging) {
    return (
      <>
        <ChargeInProgressCard
          className={css.item}
          startedAt={startedAt}
          location={chargePointName}
          estimatedPrice={totalTTC}
        />

        <ChargeInProgressInvoiceCard className={css.item} />
      </>
    );
  }

  if (lastInvoice) {
    return (
      <>
        <LastInvoiceCard
          className={css.item}
          invoice={lastInvoice}
          onClick={onClickOnInvoice}
        />
        <LastChargesCard
          className={css.item}
          consumptions={consumptions}
          onClick={onNavigateToScan}
        />
      </>
    );
  }

  return (
    <div className={css.fallback}>
      <DashboardPlaceholder />
      <Text fontSize="large">{t('dashboard.service-section.fallback')}</Text>
    </div>
  );
}
