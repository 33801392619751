import { HTMLProps, useEffect } from 'react';
import classnames from 'classnames';

import { Fade } from '../fade/fade';
import { Icon, IconName } from '../icon/icon';
import { CircleContainer } from '../circle-container/circle-container';
import { Portal } from '../portal/portal';
import { Overlay } from '../overlay/overlay';
import { SlideFromBottom } from '../slide-from-bottom/slide-from-bottom';
import css from './drawer.module.scss';

export enum DrawerStateEnum {
  CLOSE,
  OPEN,
  SMALL,
}

export interface DrawerProps extends HTMLProps<HTMLDivElement> {
  containerClassName?: string;
  wrapperId?: string;
  state?: DrawerStateEnum;
  icon?: IconName | false;
  onClose?: () => void;
}

export const Drawer = ({
  wrapperId = 'portal-drawer',
  containerClassName,
  state = DrawerStateEnum.CLOSE,
  icon,
  onClose,
  children,
  className,
}: DrawerProps): JSX.Element => {
  useEffect(() => {
    if (state === DrawerStateEnum.OPEN) {
      document.body.classList.add('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [state]);

  return (
    <Portal wrapperId={wrapperId}>
      <Fade show={state === DrawerStateEnum.OPEN}>
        <Overlay onClick={onClose} variant="dark" />
      </Fade>

      <SlideFromBottom
        show={
          state === DrawerStateEnum.OPEN || state === DrawerStateEnum.SMALL
        }>
        <div
          className={classnames(
            containerClassName,
            css.drawerContainer,
            containerClassName,
            state === DrawerStateEnum.SMALL && css.drawerContainerSmall
          )}>
          <Fade
            duration={200}
            show={
              state === DrawerStateEnum.OPEN || state === DrawerStateEnum.SMALL
            }>
            {icon && (
              <div className={classnames(css.header)}>
                <CircleContainer className={css.headerIconContainer}>
                  <Icon name={icon} />
                </CircleContainer>
              </div>
            )}
            <div className={css.drawer}>
              <Icon
                name="x-close"
                onClick={onClose}
                className={classnames(
                  css.closeIcon,
                  !onClose && css.hideCloseIcon
                )}
              />
              <div
                className={classnames(
                  css.drawerContent,
                  state === DrawerStateEnum.SMALL && css.drawerNoScroll,
                  className
                )}>
                {children}
              </div>
            </div>
          </Fade>
        </div>
      </SlideFromBottom>
    </Portal>
  );
};
