import { useMemo } from 'react';

import { Drawer, DrawerProps } from '@app/shared/drawer/drawer';
import { Logger } from '@app/core/logger/logger';
import { FaqModel } from '@app/core/model/faq.model';

import { FaqDrawerView } from './faq-drawer.view';
import { getFallbackFaqItem, getFaqItem } from '../../get-faq-item';
import css from './faq-drawer.module.scss';

interface FaqDrawerControllerProps extends DrawerProps {
  id?: string;
}

export const FaqDrawerController = (
  props: FaqDrawerControllerProps
): JSX.Element => {
  const { id, state, onClose, icon = 'help-circle' } = props;
  const fallbackFaqItem = useMemo(getFallbackFaqItem, []);

  const faqItem: FaqModel = useMemo(() => {
    if (!id) {
      return fallbackFaqItem;
    }

    const faqItemList: FaqModel[] = getFaqItem();

    const response = faqItemList?.find((item) => item.id === id);
    if (!response) {
      Logger.error('faqItem with id', id, 'is not found');
      return fallbackFaqItem;
    }

    return response;
  }, [id, fallbackFaqItem]);

  return (
    <Drawer
      state={state}
      onClose={onClose}
      icon={icon}
      wrapperId="portal-learn-more"
      containerClassName={css.drawer}>
      <FaqDrawerView faqItem={faqItem} onBack={onClose} />
    </Drawer>
  );
};
