import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { DASHBOARD_PATH } from '@app/config/app-paths.const';
import { Logo } from '@app/shared/logo/logo';
import { Appbar } from '@app/shared/appbar/appbar';
import { UseMeasureRef, useMeasure } from '@app/core/helper/use-measure';
import { Icon, IconName } from '@app/shared/icon/icon';
import { H1 } from '@app/shared/typography/heading/heading';

import {
  BaseBodyProps,
  BaseFooterProps,
  BaseHeaderProps,
  BaseLayout,
  BaseLayoutProps,
} from '../base-layout/base-layout';

import css from './page-layout.module.scss';

export interface PageLayoutProps extends BaseLayoutProps {
  colored?: boolean;
  topClassName?: string;
  withHomeButton?: boolean;
  lightLogo?: boolean;
}

const PageLayout = ({
  colored,
  className,
  topClassName,
  lightLogo,
  withHomeButton,
  children,
  ...baseLayoutProps
}: PageLayoutProps): JSX.Element => {
  const handleGoToDashboard = useCallback(() => {
    // refresh and redirect
    window.location.replace(DASHBOARD_PATH);
  }, []);

  return (
    <BaseLayout
      className={classNames(
        css.layout,
        colored && css.layoutColored,
        className
      )}
      {...baseLayoutProps}>
      <div className={classNames(css.topContainer, topClassName)}>
        <button className={css.logo} onClick={handleGoToDashboard}>
          <Logo light={colored || lightLogo} />
        </button>

        {withHomeButton && (
          <button onClick={handleGoToDashboard}>
            <Icon name="home-02" />
          </button>
        )}
      </div>

      {children}
    </BaseLayout>
  );
};

const PageHeader = ({
  className,
  ...baseHeaderProps
}: BaseHeaderProps): JSX.Element => (
  <BaseLayout.Header
    {...baseHeaderProps}
    className={classNames(css.header, className)}
  />
);

type PageHeaderProps = {
  icon?: IconName;
  backTo?: string;
  title: string;
  className?: string;
};

const PageTitle = (props: PageHeaderProps): JSX.Element => {
  const { icon, backTo, title, className } = props;
  const navigate = useNavigate();

  return (
    <div className={classNames(className, css.title)}>
      {backTo && (
        <button onClick={() => navigate(backTo)}>
          <Icon name="chevron-left" />
        </button>
      )}

      {icon && <Icon name={icon} />}

      <H1>{title}</H1>
    </div>
  );
};

interface PageBodyProps extends BaseBodyProps {
  withTexture?: boolean;
}

const PageBody = ({
  withTexture = false,
  className,
  ...baseBodyProps
}: PageBodyProps): JSX.Element => (
  <BaseLayout.Body
    className={classNames(
      css.body,
      withTexture && css.bodyWithTexture,
      className
    )}
    {...baseBodyProps}
  />
);

interface DynamicHeightFooterProps {
  children: (ref: UseMeasureRef<HTMLDivElement>) => JSX.Element;
}

const DynamicHeightFooter = (props: DynamicHeightFooterProps): JSX.Element => {
  const { children } = props;

  const [ref, { y, bottom }] = useMeasure<HTMLDivElement>();

  return (
    <div style={{ height: y + bottom }} className={css.dynamicHeightFooter}>
      {children(ref)}
    </div>
  );
};

const RawFooter = ({
  className,
  ...baseFooterProps
}: BaseFooterProps): JSX.Element => (
  <DynamicHeightFooter>
    {(ref) => (
      <BaseLayout.Footer
        {...baseFooterProps}
        className={classNames(css.footer, className)}
        ref={ref}
      />
    )}
  </DynamicHeightFooter>
);

const PageFooter = ({
  className,
  ...baseFooterProps
}: BaseFooterProps): JSX.Element => (
  <RawFooter
    {...baseFooterProps}
    className={classNames(css.footerPage, className)}
  />
);

const AppbarFooter = ({
  className,
  ...baseFooterProps
}: BaseFooterProps): JSX.Element => (
  <DynamicHeightFooter>
    {(ref) => (
      <BaseLayout.Footer
        {...baseFooterProps}
        className={classNames(css.footer, css.appbar, className)}
        ref={ref}>
        <Appbar />
      </BaseLayout.Footer>
    )}
  </DynamicHeightFooter>
);

const DrawerFooter = ({
  className,
  ...baseFooterProps
}: BaseFooterProps): JSX.Element => (
  <DynamicHeightFooter>
    {(ref) => (
      <BaseLayout.Footer
        {...baseFooterProps}
        className={classNames(className, css.footer, css.drawerFooter)}
        ref={ref}
      />
    )}
  </DynamicHeightFooter>
);

PageLayout.Title = PageTitle;
PageLayout.Header = PageHeader;
PageLayout.Body = PageBody;
PageLayout.Footer = PageFooter;
PageLayout.AppbarFooter = AppbarFooter;
PageLayout.DrawerFooter = DrawerFooter;
PageLayout.RawFooter = RawFooter;

export { PageLayout };
