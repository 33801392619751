import { ReactNode, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { TranslationPaths } from '@app/core/helper/use-typed-translation';
import { Button } from '@app/shared/button/button';
import { EmailInput } from '@app/shared/text-input/email-input';
import { H1 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';

import { Link } from '@app/shared/typography/link/link';
import { StartEmailOtpFormValidationRules } from '../email-otp-form-validation-rules.const';
import { EmailOtpProcessStartRequestModel } from '../../../core/model/email-otp-process.model';

import { buildStartEmailOtpTranslations } from './build-start-email-otp-translation';
import css from './start-email-otp-process.module.scss';

const DEFAULT_TRANSLATION_KEY: TranslationPaths = 'email-otp';

interface StartEmailOtpProcessViewProps {
  remainingLockTimeInMin?: number;
  className?: string;
  children?: JSX.Element;
  emailAddress?: string;
  autoFocus?: boolean;
  pending: boolean;
  isReadonly: boolean;
  translationKey?: TranslationPaths;
  appendFooter?: ReactNode;
  onSkip?: () => void;
  onSubmit: (payload: EmailOtpProcessStartRequestModel) => Promise<void>;
}

export const StartEmailOtpProcessView = ({
  className,
  remainingLockTimeInMin = 0,
  emailAddress = '',
  autoFocus = false,
  pending = false,
  translationKey,
  appendFooter,
  onSubmit,
  onSkip,
}: StartEmailOtpProcessViewProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    control, // Remplace register par control
    handleSubmit,
    formState: { dirtyFields, errors, isValid },
  } = useForm<EmailOtpProcessStartRequestModel>({
    defaultValues: { emailAddress },
    mode: 'onChange',
  });

  const translations = useMemo(
    () =>
      buildStartEmailOtpTranslations(translationKey ?? DEFAULT_TRANSLATION_KEY),
    [translationKey]
  );

  const defaultTranslations = useMemo(
    () => buildStartEmailOtpTranslations(DEFAULT_TRANSLATION_KEY),
    []
  );

  const handleStartEmailOtpProcessSubmit = (
    data: EmailOtpProcessStartRequestModel
  ) => {
    onSubmit({
      emailAddress: data.emailAddress.trim().toLowerCase(),
    });
  };

  return (
    <form
      onSubmit={handleSubmit(handleStartEmailOtpProcessSubmit)}
      className={classNames(css.formContainer, className)}>
      <DrawerLayout className={css.drawer}>
        <DrawerLayout.Header>
          <H1>{t([translations.title, defaultTranslations.title])}</H1>
          <Text className={css.caption}>
            {t([translations.caption, defaultTranslations.caption])}
          </Text>
        </DrawerLayout.Header>

        <DrawerLayout.Body className={css.drawerBody}>
          <Controller
            name="emailAddress"
            control={control}
            rules={StartEmailOtpFormValidationRules.emailAddress}
            render={({ field }) => (
              <EmailInput
                autoFocus={autoFocus}
                {...field}
                label={t([
                  translations.inputLabel,
                  defaultTranslations.inputLabel,
                ])}
                isTouched={dirtyFields?.emailAddress}
                errorMessage={
                  errors?.emailAddress?.type === 'pattern'
                    ? t('shared.errors.email.pattern')
                    : errors?.emailAddress?.type === 'required'
                    ? t('shared.errors.email.required')
                    : ''
                }
                required
              />
            )}
          />
          {onSkip && (
            <Link onClick={onSkip} className={css.noEmailLink}>
              {t('recover-account.email.ask-for-code.no-email-link')}
            </Link>
          )}
        </DrawerLayout.Body>

        <DrawerLayout.Footer>
          {appendFooter}
          <Button
            type="submit"
            disabled={!isValid || remainingLockTimeInMin > 0}
            isLoading={pending}>
            {t([translations.submitButton, defaultTranslations.submitButton], {
              count: remainingLockTimeInMin,
            })}
          </Button>
        </DrawerLayout.Footer>
      </DrawerLayout>
    </form>
  );
};
