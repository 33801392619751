import { ReactNode, useCallback, useState } from 'react';

import { TranslationPaths } from '@app/core/helper/use-typed-translation';
import { EmailOtpProcessStartRequestModel } from '@app/core/model/email-otp-process.model';
import { StartEmailOtpProcessView } from '@app/feature/email-otp-process/start-email-otp-process/start-email-otp-process.view';

interface StartEmailOtpProcessControllerProps {
  remainingLockTimeInMin?: number;
  className?: string;
  translationKey?: TranslationPaths;
  emailAddress?: string;
  isReadonly?: boolean;
  autoFocus?: boolean;
  appendFooter?: ReactNode;
  onRequestOTP: (payload: EmailOtpProcessStartRequestModel) => Promise<void>;
  onNext: (payload: EmailOtpProcessStartRequestModel) => void;
  onSkip?: () => void;
}

export const StartEmailOtpProcessController = (
  props: StartEmailOtpProcessControllerProps
): JSX.Element => {
  const {
    remainingLockTimeInMin,
    className,
    translationKey,
    emailAddress,
    appendFooter,
    autoFocus = false,
    isReadonly = false,
    onRequestOTP,
    onNext,
    onSkip,
  } = props;

  const [pending, setPending] = useState(false);

  const handleRequestOTP = useCallback(
    async (payload: EmailOtpProcessStartRequestModel) => {
      setPending(true);
      try {
        await onRequestOTP(payload);
        onNext(payload);
      } catch (err) {
        setPending(false);
      }
    },
    [onRequestOTP, onNext]
  );

  return (
    <StartEmailOtpProcessView
      autoFocus={autoFocus}
      remainingLockTimeInMin={remainingLockTimeInMin}
      emailAddress={emailAddress}
      isReadonly={isReadonly}
      pending={pending}
      translationKey={translationKey}
      onSubmit={handleRequestOTP}
      className={className}
      appendFooter={appendFooter}
      onSkip={onSkip}
    />
  );
};
